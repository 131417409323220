<template>
  <v-app>
    <v-row align="center" justify="center" class="ma-0">
      <v-col cols="12" class="d-flex justify-center">
        <v-card
          elevation="0"
          class="mx-auto"
          max-width="600"
          
        >
          <v-list-item three-line>
            <v-list-item-content>
              <div class="text-overline mb-4">
                404 es un error.
              </div>
              <v-list-item-title class="text-h5 mb-1">
                URL no valida
              </v-list-item-title>
              <v-list-item-subtitle>La URL o direccion solicitada no se encuentra en el servidor.Es todo lo que sabemos.</v-list-item-subtitle>
            </v-list-item-content>

            <v-img
              contain
              max-height="150"
              max-width="250"
              src="../assets/img/Logo-506.png"
            ></v-img>
          </v-list-item>

          <v-card-actions>
            <v-btn
              color="primary"
              rounded
              @click="$router.go(-1)"
            >
              Regresar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-app>
</template>

<script>
export default {

}
</script>

<style>

</style>